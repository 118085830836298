.app-landing-page-pricing {
    background-image: url(../images/img-app-landing-page-bg.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
    min-height: 90vh;
}

.landing-banner {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.landing-banner > h2 {
	font-size: 1.5em !important;
	color: #4D4C4C !important;
	text-align: center;
}

.italic-text {
	font-style: italic;
	font-size: 1.3px;
}


.landing-banner > p {
	font-size: 1em !important;
	color: #8F8E8E !important;
	text-align: center;
}

.paragraph-2 {
	color: #776B5D;
}

.sm-center .button-section .custom-btn {
    text-align: left;
}

.button-section .lower-case {
	display: inline;
}

.custom-btn {
    display: flex;
    align-items: center; 
    justify-content: center; 
}

@media (max-width: 991px) {
    .sm-center {
        text-align: center;
    }

	.button-section .custom-btn {
        text-align: center;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .button-section .custom-btn button {
        margin-top: 10px; 
    }

}

.app-landing-category {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

	border-radius: 20px !important;
	color: #8F8E8E !important;
	background-color: #ECECEC;
}

.app-landing-search {
	background-color: #FF851A !important;
	text-align: center !important;
	color: #3c3c3c !important;
	border-radius: 25px !important;
	border: 2px solid #FF851A !important;
	width: 50%;
}

.custom-card {
	background-color: #f6f6f6;
	border-radius: 30px; 
}

.landing-image {
	background-image: url(../images/img-app-landing-banner.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 600px;
	width: 100%;
  }
  
  @media (max-width: 768px) {
	.landing-image {
	  height: 300px; 
	}
  }
  
  @media (max-width: 480px) {
	.landing-image {
	  height: 200px;
	}
  }

  .dotted-underline {
    cursor: pointer;
    border-bottom: 2px dotted #FF851A;
    padding-bottom: 3px; 
}