/* AccountInfo.css */

/* Mobile and small devices */
@media (max-width: 768px) {
  .user-account-title {
    font-size: 16px;
  }
  .user-details {
    flex-direction: column;
    align-items: start;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 769px) and (max-width: 992px) {
  .user-account-title {
    font-size: 18px;
  }
}

/* Larger devices */
@media (min-width: 993px) {
  .user-account-title {
    font-size: 20px;
  }
}

