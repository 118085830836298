.dark-webdev-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: black;
    margin-top: 100px;
    margin-bottom: 100px;
}

.white-webdev-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 100px;
    margin-bottom: 100px;
}

.biz-webdev-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF851A;
    gap: 20px;
    margin-top: 100px;
    margin-bottom: 100px;
}

.new-webdev-text {
    max-width: 600px;
    margin-left: 50px;
}

.new-webdev-image {
    width: 600px;
    height: auto;
}

.secondary-webdev-text {
    color: gray !important;
    font-size: .8rem;
    text-transform: uppercase;
}

.title-webdev-text {
    font-size: 3.8rem;
    color: white !important;
}

.dark-title-webdev-text {
    font-size: 3.8rem;
    color: #1E1E1E !important;
}

.paragraph-webdev-text {
    font-size: 18px;
    text-align: start;
    color: whitesmoke;
}

.dark-paragraph-webdev-text {
    font-size: 18px;
    text-align: start;
    color: #1E1E1E !important;
}

@media (max-width: 991px) {
    .new-webdev-container {
        flex-direction: column;
        text-align: center;
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .new-webdev-text {
        margin-left: 0;
        padding: 20px;
    }

    .new-webdev-image {
        width: 100%;
        max-width: 100%;
    }

    .title-webdev-text {
        font-size: 3rem;
        text-align: center;
    }

    .paragraph-webdev-text {
        text-align: justify;
        font-size: 16px;
    }
}
