.landing-container {
    padding: 20px;
}

.section-container {
    padding: 100px;
    background-color: #FF851A;
}

.see-more-team {
    font-size: 1rem;
    color: #686D76 !important;
}

.section-container-get {
    padding: 180px;
    background-color: white;
}

.paragraph-text-behind {
    font-size: 18px;
    text-align: start;
    color: black;
}

.section-container-video {
    padding: 200px;
    background-color: white;
}

.title-brilliance {
    font-size: 5.8rem;
    color: white !important;
    padding-right: 5rem;
}

.title-brilliance-sales {
    font-size: 5.8rem;
    color: black !important;
}

.title-secondary {
    font-size: 4rem;
    color: #FF851A !important;
}

.video-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 0;
    filter: blur(8px);
}

.section-container-video {
    position: relative;
    overflow: hidden;
    height: 100vh;
}

.flex-col-video {
    z-index: 1;
    position: relative;
    color: white;
    text-shadow: 0px 0px 8px rgba(0,0,0,0.5);
}

.flex-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.flex-col {
    flex: 1;
    padding: 10px;
}

.image-col {
    max-width: 300px;
}

.content-col {
    max-width: 600px;
}

@media (max-width: 768px) {
    .flex-row {
        flex-direction: column;
    }
}

.image-size-two {
    height: 350px;
    width: 350px;
    max-width: 400px;
}

.paragraph-text {
    font-size: 14px;
    text-align: start;
}

.title-long {
    font-size: 2.5rem;
    text-align: start;
}

.text-secondary-long {
    color: orange;
    font-style: italic;
}

/* Tablet Mode */
@media (max-width: 991px) { 
    .title-brilliance {
        font-size: 2rem;
    }
    .video-background {
        height: 100%;
        filter: blur(10px);
    }
    .paragraph-text-behind {
        text-align: justify;
    }
    .title-brilliance-sales {
        font-size: 3rem;
        text-align: center;
    }
    .paragraph-text {
        font-size: 1rem;
        padding: 30px;
    }
    .rounded-pill {
        margin: 30px !important;
    }
    .title-webdev-text{
        font-size: 1.5rem;
        color: blue !important;
    }
}
@media (max-width: 575px) {
    .title-brilliance {
        font-size: 2rem;
        padding-right: 1rem;
    }
    .paragraph-text-behind {
        font-size: .7rem;
    }
    .title-brilliance-sales {
        font-size: 1.6rem;
    }
    .paragraph-text {
        font-size: 1rem;
    }
}