.business-image {
    transition: transform 0.2s ease;
    cursor: pointer;
}

.business-image:hover {
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .business-image {
        width: 100% !important;
        margin-bottom: 1rem;
    }
}

.map-container .leaflet-container {
    width: 100%;
    height: 100%;
}

.leaflet-container {
    z-index: 1;
}