.app-landing-page {
    background-image: url(../images/img-app-landing-page-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.text-section {
    flex: 2;
    padding-right: 20px;
}

.button-section {
    flex: 1;
    display: flex;
    justify-content: center;
}

h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: #FF851A !important;
}

p {
    font-family: 'Poppins', sans-serif;
    color: #060606;
    font-size: 18px;
}

.lower-case {
    font-size: 13px;
    color: #40A2D8;
    vertical-align: middle;
}

.custom-btn {
    padding: 10px 15px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    transition: background-color 0.3s;
    max-width: 400px;
}

.custom-btn-light {
    background-color: #FFECD6;
    color: #333;
    font-weight: bold;
}

.custom-btn-secondary {
    background-color: rgba(0, 0, 0, 0.1);
    color: #FBF9F1;
    font-weight: bold;
    width: auto;
    margin: 5px;
}

.custom-btn-selected {
    background-color: #FFA543;
    color: #333;
    font-weight: bold;
}

@media (max-width: 768px) {
    .content-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .text-section, .button-section {
        flex: none;
        width: 100%;
        padding-right: 0;
    }
}

.my-container {
    padding: 20px;
}

.card-container {
    flex: 1;
    padding: 10px;
    min-width: 400px; /* adjust as needed */
}

.card-container-three {
    flex: 1;
    padding: 10px;
    min-width: 200px; /* adjust as needed */
    height: 1260px;
    overflow-y: auto;
}

.card-text-amount {
    color: #FF851A;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 2.3rem;
    padding: 0px 20px;
    font-weight: bold !important;
    color: #060606;
}
.amount-subtitle {
    font-size: 1rem;
}

.card-shadow {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
}

.card-shadow:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.3);
}

.full-width-button{
    width: 100%;
    display: block;
    color: #060606;
}

.italic-text {
	font-style: italic;
	font-size: 12px;
}

.icon-transition {
    transition: transform 0.3s ease-in-out;
}

.icon-up {
    transform: rotate(0deg);
}

.icon-down {
    transform: rotate(180deg);
}

@media (max-width: 767px) {
    .collapse-content {
        display: none;
    }
}

@media (min-width: 768px) {
    .view-more-button {
        display: none;
    }
}
