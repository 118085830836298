.section-landing-website-development {
    background-image: url(../images/img-stock-bg.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 200px;
    background-color: rgba(255, 255, 255, 0.8);
}

.web-dev-h1 {
    font-size: 4.5rem;
}

.webdev-div-container {
    display: flex;             
    align-items: center;       
    justify-content: center;   
    gap: 20px;              
    background-color: #FF851A;
    flex-wrap: wrap;
}

.webdev-div-container-secondary {
    display: flex;             
    align-items: center;       
    justify-content: center;   
    gap: 20px;              
    flex-wrap: wrap;
}

.div-webdev-text {
    max-width: 600px;
}

.title-webdev-h2 {
    color: #F6F6F6;
    font-size: 3rem;
}

.paragraph-webdev-p {
    color: #F6F6F6;
    font-size: 1rem;
}

.title-webdev-h2-secondary {
    color: #A9A9A9 ;
    font-size: 3.5rem;
}

.paragraph-webdev-p-secondary {
    color: #3F3F3F;
    font-size: 1rem;
}

.services-container {
    padding-top: 20px;
}

.text-services {
    font-size: 12px;
    text-transform: uppercase;
    color: grey;
}

h1 {
    color: #FF851A;
}

.text-intro-landing {
    font-size: 14px;
    color: grey;
}

.biz-color {
    color: #FF851A;
}

.biz-bg-color {
    background-color: #FF851A;
}

.biz-bold {
    font-weight: bold !important;
    color: #000000 !important;
}

.img-carousel {
    height: 350px;
    width: auto;
    margin: auto;
}

.flex-container {
    display: flex;
    justify-content: space-between; 
    align-items: flex-start; 
    flex-wrap: wrap; 
}

.text-content, .video-container {
    flex: 1; 
    padding: 10px;
}

@media (max-width: 768px) {
    .flex-container {
        flex-direction: column; 
    }

    .text-content, .video-container {
        flex-basis: 100%; 
    }

    .video-container {
        padding-left: 0; 
        order: -1;
    }

    .webdev-div-container {
        flex-direction: column;
        padding: 20px;
    }

    .new-webdev-image {
        width: 100%;
        height: auto;
    }

    .title-webdev-h2 {
        font-size: 2.5rem; 
        text-align: center; 
    }

    .paragraph-webdev-p {
        font-size: 1rem; 
        text-align: center;
    }
}

@media (max-width: 576px) {
    .section-landing-website-development {
        padding: 50px 20px; 
    }

    .landing-dev-h5 {
        color: #F6F6F6 !important;
    }

    .div-webdev-text {
        align-items: center; 
    }

    .title-webdev-h2-secondary {
        font-size: 1.2em;
    }

    .paragraph-webdev-p-secondary {
        font-size: 0.85em;
    }

    .text-services, .text-intro-landing, h1 {
        font-size: smaller;
    }

    .webdev-div-container {
        padding: 10px; 
        flex-direction: column;
    }

    .new-webdev-image {
        width: 100%;
        height: auto;
    }

    .title-webdev-h2 {
        font-size: 2rem;
        text-align: center;
        color: #FF851A !important;
    }

    .paragraph-webdev-p {
        font-size: 0.9rem;
        text-align: center;
    }
    .web-dev-div {
        text-align: center; 
        padding: 10px;
    }

    .web-dev-h1 {
        font-size: 2.5rem; 
    }
    
    .div-margin {
        margin-top: 20px;
    }
}

h6 a .email-text, 
h6 a:link .email-text, 
h6 a:visited .email-text,
h6 a:hover .email-text,
h6 a:active .email-text {
    text-decoration: underline;
    text-decoration-color: #FF851A; 
}

.custom-img-size {
    width: 230px;
    height: 200px;
}

.center-images {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.one-webdev-container {
    display: flex;             
    align-items: center;       
    justify-content: center;   
    gap: 20px;              
    background-color: white;
    margin-bottom: 100px;
    margin-top: 100px;
}

.title-webdev-h1 {
    font-size: 3.8rem;
    color: #000000 !important;
}

.p-webdev-text {
    font-size: 16px;
    text-align: start;
    color: whitesmoke !important;
}
