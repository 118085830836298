.app-landing-page-service {
    background-image: url(../images//img-app-landing-page-bg.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
    min-height: 95vh;
}

.section-container-website-development {
    background-image: url(../images/img-stock-bg.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.9);
}

.web-dev-h1, .landing-dev-h5 {
    font-size: 1rem;
}

@media (min-width: 576px) {
    .web-dev-h1 {
        font-size: 2.5rem;
    }
    .landing-dev-h5 {
        font-size: 1rem;
        color: #FCF8F3;
    }
    .section-container-website-development {
        padding: 50px;
    }
}

@media (min-width: 768px) {
    .web-dev-h1 {
        font-size: 3rem;
    }
    .landing-dev-h5 {
        font-size: 1.25rem;
        color: #FCF8F3;
    }
    .section-container-website-development {
        padding: 100px;
    }
}

@media (min-width: 992px) {
    .web-dev-h1 {
        font-size: 5rem;
    }
    .landing-dev-h5 {
        font-size: 2rem;
        color: #FCF8F3;
    }
    .section-container-website-development {
        padding: 200px;
    }
}

.services-container {
    padding-top: 20px;
}

.text-services {
    font-size: 12px;
    text-transform: uppercase;
    color: grey;
}

h1 {
    color: #FF851A;
}

.text-intro-landing {
    font-size: 14px;
    color: grey;
}

.biz-color {
    color: #FF851A;
}

.biz-bg-color {
    background-color: #FF851A;
}

.biz-bold {
    font-weight: bold !important;
    color: #000000 !important;
}

.img-carousel {
    height: 350px;
    width: auto;
    margin: auto;
}

.flex-container {
    display: flex;
    justify-content: space-between; 
    align-items: flex-start; 
    flex-wrap: wrap; 
}

.text-content, .video-container {
    flex: 1; 
    padding: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .flex-container {
        flex-direction: column; 
    }

    .text-content, .video-container {
        flex-basis: 100%; 
    }

    .video-container {
        padding-left: 0; 
        order: -1;
    }
}

@media (max-width: 576px) {
    .text-services, .text-intro-landing, h1 {
        font-size: smaller;
    }
}

h6 a .email-text, 
h6 a:link .email-text, 
h6 a:visited .email-text,
h6 a:hover .email-text,
h6 a:active .email-text {
    text-decoration: underline;
    text-decoration-color: #FF851A; 
}

.custom-img-size {
    width: 230px;
    height: 200px;
}

.center-images {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.webdev-container {
    display: flex;             
    align-items: center;       
    justify-content: center;   
    gap: 20px;              
    background-color: #FF851A;
    margin-top: 100px;
    margin-bottom: 100px;
}

.one-webdev-container {
    display: flex;             
    align-items: center;       
    justify-content: center;   
    gap: 20px;              
    background-color: white;
    margin-bottom: 100px;
    margin-top: 100px;
}

.title-webdev-h1 {
    font-size: 3.8rem;
    color: #000000 !important;
}

.p-webdev-text {
    font-size: 16px;
    text-align: start;
    color: whitesmoke !important;
}