.landing-container-numbers {
    background-color: #FF851A;
}

.numbers-text {
    color: #222831;
    font-size: 16px;
}

.numbers-text-digits {
    color: #FFFFFF;
    font-size: 72px;
    font-family: "Prata", serif;
    font-weight: 400;
    font-style: normal;
}

.biz-color-white{
    color: #FFFFFF;
    font-family: "Prata", serif;
    font-weight: 400;
    font-style: italic;
}

.text-center {
    text-align: center; 
}

.landing-container-numbers {
    background-color: #FF851A;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.numbers-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.numbers-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 150px;
}

.divider {
    height: 100px;
    width: 5px;
    background-color: whitesmoke;
    margin: 0 20px;
}

@media (min-width: 768px) and (max-width: 992px) {
    .numbers-text-digits {
        font-size: 48px;
    }
    .numbers-text {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .numbers-flex {
        flex-direction: column;
    }
    .divider {
        width: 50%;
        height: 2px;
        margin: 10px 0;
    }
    .numbers-text-digits {
        font-size: 36px;
    }
    .numbers-text {
        font-size: 12px;
    }
}

@media (max-width: 767px) {
    .landing-container-numbers {
        display: none;
    }
}
