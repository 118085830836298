.main-content {
    min-height: 100vh;
    padding-bottom: 50px;
}

.app-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px; 
    background-color: #f5f5f5; 
    line-height: 50px; 
}  

.no-underline {
    font-size: 12px;
}

.text-left {
    font-size: 10px;
}

.learn-more {
    color: #FF851A !important;
    text-decoration: none;
}

.social-media-icons {
    display: flex;
    gap: 10px; 
    align-items: center;
    justify-content: start;
}

.social-media-icons a {
    color: #000000; 
    font-size: 1.5em; 
}

.social-media-icons a:hover {
    color: #FF851A;
}
