.app-pre-footer {
  background-color: #f8f9fa;
  color: #333;
}
  
.align-items-start h6 {
  font-size: 13px;
}
  
.footer-link {
  color: inherit;
  text-decoration: none;
}

.footer-link:hover {
  color: #FF851A;
}

.newsletter-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app-pre-footer .footer-link,
.app-pre-footer .footer-link:hover,
.app-pre-footer a,
.app-pre-footer a:hover {
  color: inherit;
  text-decoration: none;
}

.social-media-icons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap;
  margin-top: 15px;
}

.social-media-icons a {
  font-size: 20px;
  color: inherit;
}

.social-media-icons a:hover {
  color: #FF851A;
}

@media (max-width: 767px) {
  .newsletter-col {
    text-align: left;
    margin-top: 20px;
  }
  
  .social-media-icons {
    gap: 8px;
  }
}