.app-landing-page {
    background-image: url(../images/img-app-landing-page-bg.png);
    background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; 
}

.login-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    max-width: 1200px; 
    margin: auto; 
}

.login-form {
    flex: 1 1 300px; 
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.login-image {
    flex: 1 1 300px; 
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-image img {
    max-width: 100%; 
    height: auto;
}

.custom-button {
    width: 100%;
    background-color: #FF851A;
    color: white;
    border: 2px solid #FF851A;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
}

.floating-alert {
    position: fixed;
    top: 20px;  
    right: 20px;
    z-index: 1050;
}

.custom-button:hover {
    background-color: #e57e1f; 
}